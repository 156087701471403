import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";

import { spinnerAreas } from "../constants/constants";
import Spinner from "./Spinner";
import { ModifyPathPlugin, ChangeBase } from './SwaggerPlugins'

import { useSelector } from 'react-redux'

import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useParams } from "react-router-dom";

export default function ServiceSpec(props) {
  const [specJSX, setSpecJSX] = useState("");
  const [loading, setLoading] = useState(true)

  const openApis = useSelector(state => state.openApis)

  let { namespace, name } = useParams();

  function intercept(req) {
    let newReq = req;
    const url =
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "");
    const params = newReq.url.replace(url, "");
    newReq.url = `${url}/proxy${params}`;
    newReq.headers["x-request-namespace"] = namespace;
    newReq.headers["x-request-name"] = name;

    return newReq;
  }

  useEffect(() => {
    updateSpec()
  }, [namespace, name])

  useEffect(() => {
    if(loading) {
      updateSpec()
    }
  }, [openApis])

  async function updateSpec() {
    setSpecJSX(null)
    setLoading(true)
    for(let a of openApis) {
      if(namespace === a.metadata.namespace && name === a.metadata.name) {
        const spec = await getSpec()
        setSpecJSX(
          <SwaggerUI
            //plugins={[system => ModifyPathPlugin(system, a.spec.pathPrefix)]}
            spec={spec}
            requestInterceptor={intercept}
          />
        );
        setLoading(false)
      }
    }
  }

  async function getSpec() {
    return new Promise((res) => {
      fetch("/api/" + namespace + "/" + name)
        .then((res) => res.text())
        .then((data) => {
          res(JSON.parse("{" + data.split("\n").slice(1).join("\n")))
        })
        .catch(e => {
          console.log('Error getting spec', e)
        });
    })
  }

  return (
    <div className="bg-white rounded w-full shadow-md">
      {specJSX}
      <Spinner css="margin: auto;" loading={loading} />
    </div>
  );
}
