import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addOpenApi, rmOpenApi, setConnected } from '../redux/actions'

export default function Socket(props) {
  const storeConnected = useSelector(state => state.connected)
  const dispatch = useDispatch()
  const url = window.location.hostname + (window.location.port ? ":" + window.location.port : "")

  const connect = () => {
    let ws = new WebSocket(`${window.location.protocol === "http:" ? "ws" : "wss" }://${url}/api/ws`)
    
    ws.onopen = () => {
      console.log('Connected to ws')
      dispatch(setConnected(true))
      ws.send(new Blob([JSON.stringify({"type": "GETALL"}, null, 2)], {type : 'application/json'}))
    }
  
    ws.onmessage = evt => {
      const data = JSON.parse(evt.data)
      if(data.type === "OPENAPI") {
        let openApi = {
          metadata: {
            name: data.openApi.metadata.name,
            uid: data.openApi.metadata.uid,
            namespace: data.openApi.metadata.namespace
          },
          spec: data.openApi.spec,
          status: {
            title: data.openApi.status.displayName,
            description: data.openApi.status.description
          }
        }
  
        console.log('Add openApi: ', openApi.metadata.name)
  
  
        dispatch(addOpenApi(openApi.metadata, openApi.spec, openApi.status))
  
      }else if(data.type === "OPENAPIS"){
        for(let api of data.openApis) {
          let openApi = {
            metadata: {
              name: api.metadata.name,
              uid: api.metadata.uid,
              namespace: api.metadata.namespace
            },
            spec: api.spec,
            status: {
              title: api.status.displayName,
              description: api.status.description
            }
          }
  
          console.log('Add openApi: ', openApi.metadata.name)
  
  
          dispatch(addOpenApi(openApi.metadata, openApi.spec, openApi.status))
  
        }
      }else if (data.type === "REMOVE") {
        dispatch(rmOpenApi(data.uid))
      }else if (data.type === "ERROR") {
        console.log("Error recieved:", data.error)
      }else {
        console.log("Unknown response received")
      }
      
    }
  
    ws.onclose = (e) => {
      if (storeConnected) {
        console.log('Disconnected from ws')
      }else {
        console.log('ws server not found')
      }
      setTimeout(() => {
        connect()
      }, 2000)
      dispatch(setConnected(false))    
    }
  }

  // useEffect(() => {
  //   let inter
  //   if(!storeConnected) {
  //     connect()
  //     inter = setInterval(() => {
  //       connect()
  //     }, 5000)
  //   }
  //   return function (){
  //     clearInterval(inter)
  //   }
  // }, [storeConnected])
  
  useEffect(() => {
    if(!storeConnected) {
      connect()
    }
  }, [])


  return(
    <React.Fragment>
    </React.Fragment>
  )
}