import React from "react";

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { useSelector } from 'react-redux'

import OpenApiList from "./OpenApiList";
import ServiceSpec from "./ServiceSpec";
import Home from "./Home";
import Socket from './Socket'
import StaccLogo from '../img/stacc-logo-allwhite.svg'

function App() {
  const connected = useSelector(state => state.connected)

  return (
    <div className='font-sans bg-gray-100'>
      <Router>
        <header className='pt-6 pb-4 bg-stacc text-white'>
          <div className="" >
            <img className=" absolute px-4 py-3" src={StaccLogo} alt="logo" />
            <h1 className='text-center text-5xl'>
              <Link className='text-white' to='/'>
                Developer Portal
              </Link>
            </h1>
          </div>
        </header>

        {connected ? '' : <p className="text-center sticky top-0 bg-white text-xl font-bold border-red-700 border-2 border-solid">Not connected to server</p>}

        <Socket />
        <div className='flex flex-row pl-2 space-x-4'>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/:namespace/:name'>
              <div className="m-4 flex flex-row flex-grow flex-shrink space-x-4">
                <OpenApiList />
                <ServiceSpec />
              </div>
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
