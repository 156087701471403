import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import MoonLoader from "react-spinners/MoonLoader";

export default function Spinner(props) {
  const { promiseInProgress } = usePromiseTracker({ area: props.area });
  
  if(props.area) {
    return (
      promiseInProgress && (
        <div className='py-4'>
          <MoonLoader size={35} css={props.css} loading={true} />
        </div>
      )
    );
  }else {
    return (
      <div className='py-4'>
        <MoonLoader size={35} css={props.css} loading={props.loading} />
      </div>
    );
  }
  

  
}
