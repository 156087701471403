import { ADD_OPENAPI, REMOVE_OPENAPI, SET_CONNECTED } from './actions';

const initialState = {
  openApis: [],
  uids: [],
  connected: false
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_OPENAPI:
      if(!state.uids.includes(action.metadata.uid)){
        console.log(`${action.metadata.namespace}/${action.metadata.name} added to store`)
        return {
          ...state,
          openApis: [
            ...state.openApis,
            {
              metadata: action.metadata,
              spec: action.spec,
              status: action.status
            },
          ],
          uids: [
            ...state.uids, action.metadata.uid
          ]
        };
      }else {
        console.log(`${action.metadata.namespace}/${action.metadata.name} already exists in store`)
        return state
      }
    case REMOVE_OPENAPI:
      if(state.uids.includes(action.uid)) {
        return {
          ...state,
          openApis: state.openApis.filter(api => {
            if(api.metadata.uid !== action.uid) {
              return true
            }else {
              console.log("Removing " + api.metadata.namespace + "/" + api.metadata.name)
              return false
            }
          }),
          uids: state.uids.filter(uid => uid !== action.uid)
        }
      }else {
        console.log("OpenApi not removed, not found")
        return state
      }

    case SET_CONNECTED:
      return {
        ...state,
        connected: action.connected
      }
    default:
      return state;
  }
  
}

export default rootReducer;