import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import Spinner from "./Spinner";
import SearchBar from "./SearchBar";

export default function OpenApiList(props) {
  const [openApiList, setopenApiList] = useState(null);
  const [loading, setLoading] = useState(true);
  const openApis = useSelector((state) => state.openApis);
  const [filter, setFilter] = useState([])

  useEffect(() => {
    setLoading(false);
  }, [openApis]);

  useEffect(() => {
    const sorted = filter.sort((a, b) => (a.metadata.namespace + a.metadata.name).localeCompare((b.metadata.namespace + b.metadata.name)))
    
    setopenApiList(
      
      sorted.map((openApi) => (
        <Link
          key={openApi.metadata.uid}
          to={"/" + openApi.metadata.namespace + "/" + openApi.metadata.name}
        >
          <div className='text-left cursor-pointer hover:shadow-md rounded border-b-2'>
            <p className='font-bold text-lg truncate'>{openApi.status.title}</p>
            <p className='text-sm text-gray-600 truncate'>
              {openApi.metadata.namespace}/{openApi.metadata.name}
            </p>
          </div>
        </Link>
      ))
    );
  }, [filter])

  return (
    <div className='services-list p-4 w-1/5 bg-white shadow-md flex-col rounded'>
      <SearchBar
        filtered={setFilter}
        openApis={openApis}
        styling='mt-4 mb-5 px-2 text-xl placeholder-black border-gray-500 border-2 rounded max-w-full'
      />
      {openApiList}
      <Spinner css='margin: auto;' loading={loading} />
    </div>
  );
}
