import React from "react";
import { Link } from "react-router-dom";

export default function OAGridItem({ openApi }) {
  let desc = openApi.status.description ? openApi.status.description : '[No description]'

  return (
    <Link
      className=' h-56 shadow-md bg-white w-big rounded-lg hover:shadow-lg hover:border-gray-400 border cursor-pointer text-center p-2'
      to={"/" + openApi.metadata.namespace + "/" + openApi.metadata.name}
    >
      <div className='font-bold text-lg mb-1 border-b-2 border-gray-400 truncate'>{openApi.status.title}</div>
      <div className=' pb-2 text-sm truncate'>
        {openApi.metadata.namespace}/{openApi.metadata.name}
      </div>

      <div className='h-26 p-2'>
        {desc}
      
      </div>
      
    </Link>
  );
}
