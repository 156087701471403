export const ADD_OPENAPI = 'ADD_OPENAPI'
export const REMOVE_OPENAPI = 'RM_OPENAPI'
export const SET_CONNECTED = 'SET_CONNECTED'

export function addOpenApi(metadata, spec, status) {
  return { type: ADD_OPENAPI, metadata: metadata, spec: spec, status: status };
}

export function rmOpenApi(uid) {
  return { type: REMOVE_OPENAPI, uid: uid}
}

export function setConnected(connected) {
  return { type: SET_CONNECTED, connected: connected}
}