import React, { useState, useEffect } from 'react'

import stringSimilarity from 'string-similarity'

export default function SearchBar(props) {
  const [searchTerm, setSearchTerm] = useState("")
  const similarity = 0.7
  
  useEffect(() => {
    
    const filtered = props.openApis.filter(openApi => {
      const namespacename = inWord(openApi.metadata.namespace.toLowerCase() + '/' + openApi.metadata.name.toLowerCase(), searchTerm.toLowerCase())
      const title = inWord(openApi.status.title.toLowerCase(), searchTerm.toLowerCase())
      const name = inWord(openApi.metadata.name.toLowerCase(), searchTerm.toLowerCase())
      const namespace = inWord(openApi.metadata.namespace.toLowerCase(), searchTerm.toLowerCase())
      
      let desc = false
      if (openApi.status.description) {
        desc =  inWord(openApi.status.description.toLowerCase(), searchTerm.toLowerCase())
      }

      return namespacename || title || name || namespace || desc
    })

    props.filtered(filtered)

  }, [searchTerm, props.openApis])

  function inWord(word, term) {
    if (term.length >= word.length) {
      return stringSimilarity.compareTwoStrings(word, term) >= similarity
    }

    for(let i = 0; i + term.length <= word.length; i++) {
      if (stringSimilarity.compareTwoStrings(word.slice(i, i + term.length), term) >= similarity) {
        return true
      }
    }

    return false
  }

  return(
    <input className={props.styling} type="text" placeholder="Search for openapi" onChange={e => setSearchTerm(e.target.value)} value={searchTerm} />
  )
}