import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux'

import Spinner from "./Spinner";
import SearchBar from './SearchBar'
import OAGridItem from './OAGridItem'

export default function Home(props) {
  const openApis = useSelector((state) => state.openApis)
  const [searchResults, setSearchResults] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if(openApis != []) {
      setLoading(false)
    }else {
      setLoading(true)
    }
  }, [openApis])

  function handleFiltered(filtered) {
    let sorted = filtered.sort((a, b) => (a.metadata.namespace + a.metadata.name).localeCompare((b.metadata.namespace + b.metadata.name)))
    
    const statuses = sorted.map((openApi) => (
      <OAGridItem openApi={openApi} key={openApi.metadata.uid} />
    ))
    setSearchResults(statuses)
  }

  return(
    <div className="text-center container mx-auto">
      <SearchBar filtered={handleFiltered} openApis={openApis} styling="placeholder-black m-4 px-4 border shadow-sm border-gray-500 mb-10 text-2xl rounded" />
      <div className="grid grid-cols-3 gap-4 justify-left text-left ">
        {!loading ? searchResults : <Spinner css="margin: auto;" loading={true} />}
      </div>
    </div>
  )
}